<template>
  <section
    id="what"
    ref="what"
  >
    <b-container class="px-md-0">
      <b-row>
        <b-col>
          <h2
            class="sr-only"
            v-html="$t('hos.what.title')"
          ></h2>
          <b-tabs
            v-model="tabs.tabindex"
            align="center"
            card
            pills
          >
            <b-tab
              active
              class="p-0"
              :title="$t('hos.what.tab1.label')"
              @click="tabs.animate = false"
            >
              <b-row>
                <b-col
                  md="6"
                  lg="7"
                >
                  <web-screen :img="`${$t('baseurl')}img/screens/1.png`" />
                </b-col>
                <b-col
                  class="px-3"
                  md="6"
                  lg="5"
                >
                  <div v-html="$t('hos.what.tab1.desc')"></div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              class="p-0"
              :title="$t('hos.what.tab2.label')"
              @click="tabs.animate = false"
            >
              <b-row>
                <b-col
                  md="6"
                  lg="7"
                  order-md="2"
                >
                  <web-screen :img="`${$t('baseurl')}img/screens/2.png`" />
                </b-col>

                <b-col
                  class="px-3"
                  md="6"
                  lg="5"
                >
                  <div v-html="$t('hos.what.tab2.desc')"></div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              class="p-0"
              :title="$t('hos.what.tab3.label')"
              @click="tabs.animate = false"
            >
              <b-row>
                <b-col
                  md="6"
                  lg="7"
                >
                  <web-screen :img="`${$t('baseurl')}img/screens/3.png`" />
                </b-col>
                <b-col
                  class="px-3"
                  md="6"
                  lg="5"
                  v-html="$t('hos.what.tab3.desc')"
                />
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    WebScreen,
  },

  data() {
    return {
      tabs: {
        count: Object.keys(this.$t('hos.what')).filter(k => k.includes('tab')).length,
        tabindex: 0,
        animate: true,
      },
    };
  },

  created() {
    const animation = setInterval(() => {
      if (this.tabs.animate) {
        if (this.tabs.tabindex + 1 < this.tabs.count) {
          this.tabs.tabindex += 1;
        } else {
          this.tabs.tabindex = 0;
          this.tabs.animate = false;
        }
      } else {
        clearInterval(animation);
      }
    }, 7500);
  },
};
</script>

<style lang="scss">
#what {
  padding: 4rem 0;

  .card-header {
    border: none;
    background: transparent;
    padding: 0;

    .nav li {
      background: var(--f-g1);
      padding: 0.75rem 1.25rem;
    }
  }

  .card-body .row {
    align-items: center;
  }

  h3 {
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 1;
    margin: 0.5rem 0 1rem;

    @media (max-width: 992px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  p {
    font-size: 1.35rem;
    font-weight: 200;
    line-height: 1.4;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }
}
</style>
