<template>
  <div>
    <section
      id="intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row>
          <b-col
            md="7"
            lg="6"
          >
            <h1 v-html="$t('hos.intro.title')"></h1>
            <h2 v-html="$t('hos.intro.subtitle')"></h2>
            <b-row
              class="buttons"
              tag="ul"
            >
              <b-col
                md="8"
                tag="li"
                class="pl-0 pb-2 pb-md-3"
              >
                <b-button
                  block
                  size="lg"
                  variant="primary h-100"
                  :href="`${$t('link.vox')}/dashboard`"
                >
                  <span v-html="$t('hos.intro.btn-create')"></span>
                </b-button>
              </b-col>
              <b-col
                md="8"
                tag="li"
                class="pl-0 pb-2 pb-md-3"
              >
                <b-button
                  block
                  :href="$t('hos.data.docs')"
                  size="lg"
                  variant="outline-warning"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play mr-1 fc-g7"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[1].$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('hos.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import ModalDocs from '../../../commons/components/ModalDocs.vue';

export default {
  components: {
    ModalDocs,
  },

  data() {
    return {
      docs: false,
    };
  },
};
</script>

<style lang="scss">
#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../public/img/intro.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-d8);
    font-size: 5rem;
    font-weight: 400;
    margin: 4rem 0 3rem;
    text-shadow: #fff9 0 0 3px;

    @media (max-width: 992px) {
      font-size: 4rem;
      margin: 3rem 0 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      margin: 2rem 0 1.5rem;
    }
  }

  h2 {
    color: var(--f-d9);
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
    text-shadow: #fff9 0 0 3px;

    @media (max-width: 992px) {
      max-width: 75%;
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  #learn-more {
    label {
      color: var(--f-d9);
      background: none;
      text-decoration: none;
      text-shadow: #fff9 0 0 3px;

      i::before {
        color: var(--f-d8);
      }
    }

    span {
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

 .buttons {
    margin: 4rem 0 5.5rem;
    padding: 0;
    list-style: none;
    max-width: 90%;

    @media (max-width: 768px) {
      margin: 3rem 0 1.5rem;
    }

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 45%;
    }

    @media (max-width: 576px) {
      max-width: 66%;
    }

    > li {
      display: flex;
      align-items: center;

      > div > .btn,
      > .btn {
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.5rem 1rem;
        white-space: normal;

        @media (max-width: 992px) {
          font-size: 1rem;
        }

        @media (max-width: 576px) {
          font-size: .875rem;
        }
      }

      .btn {
        font-weight: bold;
      }
    }

    .dropdown {
      width: 100%;
    }

    .dropdown-item {
      padding: 0;

      &:active, &:focus, &:hover {
        color: var(--f-g8);
        background: var(--f-g3);
      }

      > .d-flex {
        padding: .5rem 1rem;
        white-space: normal;
      }
    }

    .dropdown-menu {
      background-color: var(--f-g1);

      li {
        width: 420px;
        @media (max-width: 460px) {
          width: 320px;
        }
      }
    }
  }

  .btn-outline-warning {
    background: #fff;
    border-color: #fff;
    font-weight: bold;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      border-color: var(--f-o7);
      color: #fff;
    }
  }
}
</style>
